import React from 'react'
import './services.css'
import { FaRegCheckCircle } from "react-icons/fa";

const Services = () => {
return (
<section id='services'>
  <h5>What I offer</h5>
  <h2>Services</h2>

  <div className='container services__container'>
    <article className='service'>
      <div className='service__head'>
        <h3>UI/UX Design</h3>
      </div>

      <ul className='service__list'>
        <li>
          <FaRegCheckCircle className='services__list-icon' />
          <p>Research best possible ways for the Client and the Users.</p>
        </li>
        <li>
          <FaRegCheckCircle className='services__list-icon' />
          <p>Implement a smooth transition from each page of Web/Mobile Apps.</p>
        </li>
        <li>
          <FaRegCheckCircle className='services__list-icon' />
          <p>Make sure everything is according to the client's design and their brand.</p>
        </li>
        <li>
          <FaRegCheckCircle className='services__list-icon' />
          <p>User experience is seemless and easy to navigate for all user groups.</p>
        </li>
        <li>
          <FaRegCheckCircle className='services__list-icon' />
          <p>Ever growing from feedback data and required updates.</p>
        </li>
      </ul>
    </article>
    {/* END OF UI/UX */}
    <article className='service'>
      <div className='service__head'>
        <h3>Web Development</h3>
      </div>

      <ul className='service__list'>
        <li>
          <FaRegCheckCircle className='services__list-icon' />
          <p>Display ample information of the client's business.</p>
        </li>
        <li>
          <FaRegCheckCircle className='services__list-icon' />
          <p>Update any new information or products the client would like.</p>
        </li>
        <li>
          <FaRegCheckCircle className='services__list-icon' />
          <p>Assist in reconciling errors and finding solutions to optimize the site.</p>
        </li>
        <li>
          <FaRegCheckCircle className='services__list-icon' />
          <p>Build on exsiting websites or implement from scratch.</p>
        </li>
      </ul>
    </article>
    {/* END OF WEB DEVELOPMENT */}
    <article className='service'>
      <div className='service__head'>
        <h3>Mobile Application</h3>
      </div>

      <ul className='service__list'>
        <li>
          <FaRegCheckCircle className='services__list-icon' />
          <p>Develop for Android users and optimize for all devices.</p>
        </li>
        <li>
          <FaRegCheckCircle className='services__list-icon' />
          <p>Update any erros and test for any issues.</p>
        </li>
        <li>
          <FaRegCheckCircle className='services__list-icon' />
          <p>Update interface experience for clients and users.</p>
        </li>
        <li>
          <FaRegCheckCircle className='services__list-icon' />
          <p>Learn new ways to make all experiences better for users.</p>
        </li>
        <li>
          <FaRegCheckCircle className='services__list-icon' />
          <p>Work with a team to always evolve the product.</p>
        </li>
      </ul>
    </article>
    {/* END OF CONTENT CREATION */}
  </div>
</section>
)
}

export default Services