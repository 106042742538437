import React from 'react'
import './nav.css'
import {AiOutlineHome} from 'react-icons/ai'
import {SiAboutdotme} from 'react-icons/si'
import {BsPersonWorkspace} from 'react-icons/bs'
import {FaCogs} from 'react-icons/fa'
import { MdOutlineEmail } from "react-icons/md";
import { useState } from 'react'

const Nav = () => {
const[activeNav, setActiveNav] = useState('#')
return (
<nav>
  <a href='#' onClick={()=> setActiveNav('#')} className={activeNav === '#' ? 'active' : ''}>
    <AiOutlineHome /></a>
  <a href='#about' onClick={()=> setActiveNav('#about')} className={activeNav === '#about' ? 'active' : ''}>
    <SiAboutdotme /></a>
  <a href='#experience' onClick={()=> setActiveNav('#experience')} className={activeNav === '#experience' ? 'active' :
    ''}>
    <BsPersonWorkspace /></a>
  <a href='#services' onClick={()=> setActiveNav('#services')} className={activeNav === '#services' ? 'active' : ''}>
    <FaCogs /></a>
  <a href='#contact' onClick={()=> setActiveNav('#contact')} className={activeNav === '#contact' ? 'active' : ''}>
    <MdOutlineEmail /></a>
</nav>
)
}

export default Nav