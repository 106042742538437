import React from "react";
import './about.css'
import ME from '../../assets/profile-pic-4.png'
import {BsPersonWorkspace} from 'react-icons/bs'
import {FaUniversity} from 'react-icons/fa'
import {BsFillFileEarmarkCodeFill} from 'react-icons/bs'

const About = () => {
return (
<section id="about">
    <h5>Get To Know</h5>
    <h2>About Me</h2>
    <div className="container about__container">
        <div className="about__me">
            <div className="about__me-image">
                <img src={ME} alt="About Image" />
            </div>
        </div>

        <div className="about__content">
            <div className="about__cards">
                <article className="about__card">
                    <BsPersonWorkspace className="about__icon" />
                    <h4>Experience</h4>
                    <small>&lt; 2 years</small>
                </article>
            </div>

            <div className="about__cards">
                <article className="about__card">
                    <FaUniversity className="about__icon" />
                    <h4>Education</h4>
                    <h5>Georgia Gwinnett College</h5>
                    <small>Bachelor's in Software Development</small>
                </article>
            </div>

            <div className="about__cards">
                <article className="about__card">
                    <BsFillFileEarmarkCodeFill className="about__icon" />
                    <h4>Coding Languages</h4>
                    <small>ReactJS, HTML5, CSS3, Javascript, Kotlin, Flutter</small>
                </article>
            </div>

            <p>
                I am always dedicated to learn and grow in coding. Learning new programming languages and impplementing
                them is a passion of mine. I want to help people have a great User Experience whenever they use a
                website or a mobile app.
            </p>

            <a href="#contact" className="btn btn-primary">Let's Talk</a>

        </div>
    </div>
</section>
)
}

export default About