import React from "react";
import "./contact.css";
import { MdOutlineEmail } from "react-icons/md";
import { FaFacebookMessenger } from "react-icons/fa";
import { BsWhatsapp } from "react-icons/bs";
import { useRef } from 'react';
import emailjs from 'emailjs-com'
import arrow from '../../assets/PngItem_340.png'

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_9jivcw5', 'template_lacoxad', form.current, 'XRvq_uSgmv4CSEQYu')

    e.target.reset()
    };

return (
<section id="contact">
  <h5>Get In Touch</h5>
  <h2>Contact Me</h2>

  <div className="container contact__container">
    <div className="contact__options">
      <article className="contact__option">
        <img className="contact__option-img" src={arrow} alt="Send That Message!" />
        {/* <MdOutlineEmail className="contact__optioin-icon"/> */}
        {/* <h4>Get</h4> */}
        {/*<h5>matsilv654@gmail.com</h5>*/}
        {/* <a href="mailto:matsilv654@gmail.com">Send a message</a> */}
      {/* </article>
      <article className="contact__option"> */}
        {/* <FaFacebookMessenger className="contact__optioin-icon"/> */}
        {/* <h4>In</h4> */}
        {/* <h5>Matheus Larson-Silva</h5>
        <a href="https://m.me/Silva15" target="_blank">
          Send a message
        </a> */}
      {/* </article>
      <article className="contact__option"> */}
        {/* <BsWhatsapp className="contact__optioin-icon"/> */}
        {/* <h4>Touch!</h4> */}
        {/* <h5>matsilv654@gmail.com</h5>*/}
        {/* <a href="https://api.whatsapp.com/send?phone=+41798188695" target="_blank">
          Send a message
        </a> */}
      </article>
    </div>
    <form ref={form} onSubmit={sendEmail}>
      <input type="text" name="name" placeholder="Your Full Name" required />
      <input type="email" name="email" placeholder="Your Email" required />
      <textarea name="message" rows="7" placeholder="Your Message" required></textarea>
      <button type="submit" className="btn btn-primary">
        Send Message
      </button>
    </form>
  </div>
</section>
);
};

export default Contact;